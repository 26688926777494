import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import CommonHeading from "../../component/Common/CommonHeading";
import { useGetpaymentListQuery } from "../../store/paymentHistory/paymentHistorySlice";
import Loader from "../../component/Common/Loader";
import HistoryTable from "../../component/Common/PageComponent/HistoryComponent/HistoryTable";
import Pagination from "../../component/Pagination/Pagination";
import { toastifyMsg } from "../../helpers/commonFunction";

const PaymentHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number);
  const [payment_for, setPayment_for] = useState("");

  console.log("payment_for", payment_for);

  const {
    data: response,
    isLoading,
    error,
    isError,
    isSuccess,
  } = useGetpaymentListQuery({
    start: +pageNumber !== 1 && (pageNumber - 1) * 10,
    limit: 10,
    payment_for: payment_for,
  });
  console.log("response", response);

  const totalCount = response?.totalCount;

  let history = [];
  if (isError) {
    toastifyMsg("error", error?.data?.message);
  } else if (isSuccess) {
    history = response.apiResponse;
  }

  const onPageChange = () => {
    setPageNumber(page_number);
  };

  const handlePaymentFilterChange = (e) => {
    setPayment_for(e.target.value);
  };

  useEffect(() => {
    navigate(`/payment-history/page/1`);
  }, [payment_for]);

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="PAYMENT HISTORY" />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="creat_edit_project_btn_row_flex">
              <div></div>
              <div className="common_filter_input_box first_filter_container">
                <div className="common_select_box">
                  <Form.Select
                    onChange={handlePaymentFilterChange}
                    id="payment_for"
                    name="payment_for"
                    className="select_form"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Filter By Category
                    </option>
                    <option value="">ALL</option>
                    <option value="LICENCE">Licence</option>
                    <option value="MESSAGE_RECORD">Message Record</option>
                  </Form.Select>
                </div>
              </div>
            </div>

            <div className="custom_data_table_content">
              <HistoryTable
                list={history}
                //   ShowDeleteSalonModal={ShowDeleteSalonModal}
              />
            </div>
            {totalCount > 0 && (
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PaymentHistory;
