import React from "react";
import { deleteIcon } from "../../../../icons";

const ViewImgIcons = ({ images, showModal, showDeleteModal, category }) => {
  console.log("category", category);
  return (
    <>
      <div className="creat_edit_project_btn_row_flex">
        <div>
          <button className="comman_btn ml-auto" onClick={showModal}>
            {category ? "Add Images" : "Add Icons"}
          </button>
        </div>
      </div>
      <div className="project_service_main_div">
        {images?.length === 0 || !images ? (
          <p className="no_data_found no_image_msg">No data Found</p>
        ) : (
          <>
            {images?.map((image, index) => {
              return (
                <div className="project_service_main_div_row" key={image?.id}>
                  <div className="service_icon_card">
                    <span
                      className="img_delete_btn"
                      role="button"
                      onClick={() => showDeleteModal(image?.id)}
                    >
                      {deleteIcon}
                    </span>

                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL}/${
                        category ? image?.category_image : image?.icon_image
                      }`}
                      alt="service_icons"
                    />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default ViewImgIcons;
