import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import AddIconModal from "../../component/Common/Modals/AddIconModal";
import DeleteIconModal from "../../component/Common/Modals/DeleteIconModal";
import ViewImgIcons from "../../component/Common/PageComponent/IconAndCategoryComponent/ViewImgIcons";
import { toastifyMsg } from "../../helpers/commonFunction";
import { useFilesUploadMutation } from "../../store/fileUploadSlice/filesUploadSlice";
import {
  useAddServiceCategoryMutation,
  useDeleteServiceCategoryMutation,
  useGetServiceCategoryQuery,
} from "../../store/serviceCategory/serviceCategorySlice";

const ServiceCategory = () => {
  const { data: serviceCategory, isLoading: serviceCategoryLoading } =
    useGetServiceCategoryQuery();

  console.log("serviceCategory", serviceCategory);

  const [addModal, setAddmodal] = useState(false);

  const [imgErrors, setImgErrors] = useState(false);
  const [image, setImage] = useState([]);
  const [imagePrev, setImagePrev] = useState([]);
  const [imgId, setImgId] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const showModal = () => {
    setAddmodal(true);
  };

  const hideModal = () => {
    setAddmodal(false);
    setImgErrors("");
    setImage([]);
    setImagePrev([]);
  };

  const [fileUpload, { isLoading: uploading }] = useFilesUploadMutation();

  const [
    addCategoryIcon,
    {
      data: fileUploadData,
      isLoading: adding,
      error: addError,
      isError: addIsError,
      isSuccess: addIsSuccess,
    },
  ] = useAddServiceCategoryMutation();

  const [
    deleteCategoryIcon,
    {
      isLoading: deleting,
      error: deleteError,
      isError: deleteIsError,
      isSuccess: deleteIsSuccess,
    },
  ] = useDeleteServiceCategoryMutation();

  useEffect(() => {
    if (addIsError) {
      toastifyMsg("error", addError?.data?.message);
    } else if (addIsSuccess) {
      setAddmodal(false);
      setImgErrors("");
      setImage([]);
      setImagePrev([]);

      toastifyMsg("success", fileUploadData?.message);
    }
  }, [addIsError, addError, addIsSuccess]);

  useEffect(() => {
    if (deleteIsError) {
      toastifyMsg("error", deleteError?.data?.message);
    } else if (deleteIsSuccess) {
      setDeleteModal(false);

      toastifyMsg("success", "Service category image has been deleted!");
    }
  }, [addIsError, addError, deleteIsSuccess]);

  const hideDeleteModal = () => {
    setDeleteModal(false);
    setImgId("");
  };

  const showDeleteModal = (id) => {
    setDeleteModal(true);
    setImgId(id);
  };

  console.log("imgId", imgId);

  const deleteImgHandler = () => {
    const data = {
      delete_status: "1",
    };
    deleteCategoryIcon({ data, id: imgId });
    setDeleteModal(false);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"CATEGORY IMAGES"} />
        <>
          {serviceCategoryLoading || uploading || adding || deleting ? (
            <Loader />
          ) : (
            <ViewImgIcons
              images={serviceCategory}
              showModal={showModal}
              showDeleteModal={showDeleteModal}
              category={true}
            />
          )}
        </>
      </div>

      <Modal
        className="modal_main_component modal_background_content modal_add_img_content"
        show={addModal}
        onHide={hideModal}
        centered
      >
        <AddIconModal
          hideModal={hideModal}
          imagePrev={imagePrev}
          imgErrors={imgErrors}
          setImage={setImage}
          image={image}
          setImagePrev={setImagePrev}
          setImgErrors={setImgErrors}
          addCategoryIcon={addCategoryIcon}
          fileUpload={fileUpload}
          setAddmodal={setAddmodal}
        />
      </Modal>
      <Modal
        className="modal_main_component modal_background_content"
        show={deleteModal}
        onHide={hideDeleteModal}
        centered
      >
        <DeleteIconModal
          hideDeleteModal={hideDeleteModal}
          deleteImgHandler={deleteImgHandler}
          category={true}
        />
      </Modal>
    </>
  );
};

export default ServiceCategory;
