import moment from "moment";
import React from "react";
import { downArrow, upArrow } from "../../../../icons";
import { tableHeadItemsForHistory } from "./HistoryPageComponent";

const HistoryTable = ({ list }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForHistory.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((history, i) => {
              return (
                <tr className="custom_data_table_row" key={history.id}>
                  <td className="custom_data_table_item table_item">
                    {history.payment_for === "LICENCE"
                      ? "Licence"
                      : history.payment_for === "MESSAGE_RECORD"
                      ? "Message"
                      : ""}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {history.salon_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {history.salon_owner_name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {history.payment_amount}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {moment(history.updated_at).utc().format("DD MMM YYYY")}
                  </td>
                  <td
                    className={`custom_data_table_item table_item ${
                      history.payment_status === "CREDITED"
                        ? "credited_green"
                        : "debited_red"
                    }`}
                  >
                    {history.payment_status === "CREDITED"
                      ? upArrow
                      : downArrow}{" "}
                    {history.payment_status === "CREDITED" ? "Credit" : "Debit"}
                  </td>
                  <td
                    className={`custom_data_table_item table_item ${
                      history.message_status
                        ? history.message_status === "CREDITED"
                          ? "credited_green"
                          : "debited_red"
                        : ""
                    }`}
                  >
                    {history.message_status
                      ? history.message_status === "CREDITED"
                        ? upArrow
                        : downArrow
                      : "-"}
                  </td>
                  {/* <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        className="custom_data_table_view_edit_item_btn"
                        aria-label="view"
                        //   to={`/view-salon/${salon.id}`}
                        //   state={name === "salon" && { salon: salon }}
                      >
                        {eyeIcon}
                      </Link>
                      <Link
                        aria-label="edit"
                        // to={`/update-coupon/${coupon.id}`}
                        className="custom_data_table_view_edit_item_btn"
                      >
                        {editIcon}
                      </Link>

                      <button
                        aria-label="delete"
                        className="custom_data_table_view_edit_item_btn delete_btn"
                        //   onClick={() => ShowDeleteSalonModal(salon?.id)}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default HistoryTable;
