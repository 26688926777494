import moment from "moment";
import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import CommonHeading from "../../component/Common/CommonHeading";
import Loader from "../../component/Common/Loader";
import UpdateLicenceModal from "../../component/Common/Modals/UpdateLicenceModal";
import {
  checkDateIsPastOrNot,
  newExpiryFromOldExpiry,
} from "../../component/Common/PageComponent/SalonComponent/SalonPageComponents";
import { useForm } from "../../hooks/useForm";
import { backBtnArrow } from "../../icons";
import {
  useCreateLicenceMutation,
  useGetLicenceListQuery,
  // useUpdateLicenceMutation,
} from "../../store/licence/licenceSlice";
import Pagination from "../../component/Pagination/Pagination";
import { toastifyMsg } from "../../helpers/commonFunction";

const LicenceList = React.lazy(() =>
  import("../../component/Common/PageComponent/LicenceComponent/LicenceList")
);

const Licence = () => {
  const params = useParams();
  console.log("params", params);
  const page_number = params?.page_number;

  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(page_number);

  const { data: licenceData, isLoading: licenceLoading } =
    useGetLicenceListQuery({
      user_id: params.id,
      salon_id: params.salon_id,
      start: +pageNumber !== 1 && (pageNumber - 1) * 10,
      limit: 10,
    });
  console.log("licenceData", licenceData);

  const totalCount = licenceData?.totalCount;
  console.log("totalCount98765", totalCount);

  const [createLicence, { data, isLoading, error, isError, isSuccess }] =
    useCreateLicenceMutation();

  const initialFValues = {
    addUpdateLicenceModal: false,
    new_expiry_month: 1,
    licence_payment: "",
    licenceExpiryDate: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("licence_payment" in fieldValues) {
      temp.licence_payment = fieldValues.licence_payment
        ? +fieldValues.licence_payment > 0
          ? ""
          : "Please Enter Valid Payment Amount!"
        : "Please Enter Payment Amount!";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  console.log("values", values);

  /// ADD Modal
  const showAddLicenceModal = () => {
    setValues({
      ...values,
      licenceExpiryDate:
        licenceData?.apiResponse.length !== 0
          ? licenceData?.apiResponse.length === 1
            ? licenceData?.apiResponse[0]?.licence_expiry_date
            : licenceData?.apiResponse[0]?.licence_expiry_date
          : new Date(),
      addUpdateLicenceModal: true,
      new_expiry_month: 1,
      licence_payment: "",
    });
  };
  //

  const hideModal = () => {
    setValues({
      ...values,
      addUpdateLicenceModal: false,
      new_expiry_month: 1,
      licence_payment: "",
    });
    setErrors({
      ...errors,
      licence_payment: "",
    });
  };

  const increaseDateHandler = () => {
    setValues({
      ...values,
      new_expiry_month: (values.new_expiry_month += 1),
    });
  };

  const decreaseDateHandler = () => {
    if (values.new_expiry_month > 0) {
      setValues({
        ...values,
        new_expiry_month: (values.new_expiry_month -= 1),
      });
    }
  };

  ///// Check the old expiry Date is not in PAST //////
  var oldExpiryDate = moment(values.licenceExpiryDate)
    .format("DD/MM/YYYY")
    .split("T")[0];
  console.log("oldExpiryDate", oldExpiryDate);

  let lastExpiryDate = "";
  if (checkDateIsPastOrNot(oldExpiryDate)) {
    lastExpiryDate = new Date();
  } else {
    lastExpiryDate = values.licenceExpiryDate;
  }
  console.log("lastExpiryDate", lastExpiryDate);
  console.log("values.new_expiry_month", values.new_expiry_month);
  console.log("values.licenceExpiryDate", values.licenceExpiryDate);
  //

  /// Get New Expiry from Old Expiry
  const newExpiryDateString = newExpiryFromOldExpiry(
    lastExpiryDate,
    values.new_expiry_month
  );
  console.log("lastAndNewDate", lastExpiryDate, "=====>", newExpiryDateString);
  //

  useEffect(() => {
    if (isError) {
      toastifyMsg("error", error?.data?.message);
      setValues({
        ...values,
        addUpdateLicenceModal: false,
      });
      console.log("Error", error);
    } else if (isSuccess) {
      toastifyMsg("success", data?.message);
      setValues({
        ...values,
        addUpdateLicenceModal: false,
        new_expiry_month: 1,
        licence_payment: "",
      });
    }
  }, [isError, error, isSuccess]);

  const createUpdateLicenceHandler = () => {
    if (validate()) {
      let data;
      data = {
        licence_active: "1",
        licence_expiry_date: newExpiryDateString,
        licence_payment: values.licence_payment,
        licence_period_in_month: values.new_expiry_month,
        user_id: params.id,
        salon_id: params.salon_id,
      };
      console.log("data ===> CREATE", data);
      createLicence(data);
      setValues({
        ...values,
        addUpdateLicenceModal: false,
      });
    }
  };

  console.log("values", values);

  // page change handler
  const onPageChange = () => {
    setPageNumber(page_number);
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"LICENCE MANAGEMENT"} />
        <>
          {isLoading || licenceLoading ? (
            <Loader />
          ) : (
            <>
              <div className="project_edit_main_link">
                <div className="comman_center_btn_container">
                  <button className="go_back_btn" onClick={() => navigate(-1)}>
                    {backBtnArrow} Back
                  </button>
                  <div className="project_extra_btn_head">
                    <button
                      className="comman_btn extra_btn_row"
                      onClick={() => showAddLicenceModal()}
                    >
                      Create Licence
                    </button>
                  </div>
                </div>
                <Suspense fallback={<Loader />}>
                  <LicenceList licenceData={licenceData?.apiResponse} />
                </Suspense>
              </div>
              <Pagination
                totalRecords={totalCount}
                onPageChange={onPageChange}
              />
            </>
          )}
        </>
      </div>
      <Modal
        className="modal_main_component modal_background_content"
        show={values.addUpdateLicenceModal}
        onHide={hideModal}
        centered
      >
        <UpdateLicenceModal
          licenceId={values.licenceId}
          hideModal={hideModal}
          values={values}
          increaseDateHandler={increaseDateHandler}
          decreaseDateHandler={decreaseDateHandler}
          setValues={setValues}
          createUpdateLicenceHandler={createUpdateLicenceHandler}
          errors={errors}
          handleInputChange={handleInputChange}
        />
      </Modal>
    </>
  );
};

export default Licence;
