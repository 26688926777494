import { apiSlice } from "../api/apiSlice";
import { authHeaders } from "../../helpers/axios";

export const serviceCategorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceCategory: builder.query({
      query: () => {
        return {
          url: `/smart-beauty/salon/get-service-category-images`,
          method: "GET",
          headers: authHeaders(),
        };
      },
      providesTags: ["ServiceCategory"],
    }),

    addServiceCategory: builder.mutation({
      query: (data) => {
        return {
          url: `/smart-beauty/salon/add-service-category-image`,
          method: "POST",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["ServiceCategory"],
    }),

    deleteServiceCategory: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/smart-beauty/salon/update-service-category-image/${id}`,
          method: "PUT",
          headers: authHeaders(),
          body: data,
        };
      },
      invalidatesTags: ["ServiceCategory"],
    }),
  }),
});

export const {
  useGetServiceCategoryQuery,
  useAddServiceCategoryMutation,
  useDeleteServiceCategoryMutation,
} = serviceCategorySlice;
